<template>
    <span style="margin: 0">
        <span class="title" v-if="Boolean(depSearch)">部门</span>
        <el-cascader
            v-if="Boolean(depSearch)"
            size="small"
            ref="cascader"
            v-model="departmentId"
            :options="departmentList"
            :style="{ width: departmentWidth + 'px' }"
            :show-all-levels="false"
            clearable
            @change="changea"
            :props="{
                checkStrictly: true,
                expandTrigger: 'hover',
                emitPath: false,
                value: 'id',
                label: 'departmentName',
                children: 'children',
            }"
        >
            <template slot-scope="{ node, data }">
                <div @click="cascaderClick(data)">
                    <span>{{ data.departmentName }}</span>
                </div>
            </template>
        </el-cascader>
        <span class="title" v-if="Boolean(searchUser) && searchType != '我的'"
            >成员</span
        >
        <el-select
            v-if="Boolean(searchUser) && searchType != '我的'"
            :style="{ width: adminWidth + 'px' }"
            v-model="adminId"
            size="small"
            filterable
            clearable
            placeholder="请选择"
            @change="changeAdmin"
        >
            <el-option
                v-for="item in userName"
                :disabled="Boolean(isSaleCost) && myAdminId == item.id"
                :label="item.nickName"
                :value="item.id"
            >
            </el-option>
        </el-select>
        <!-- <el-select
            v-model="searchVal.departmentId"
            v-if="isJurisdiction"
            size="small"
            filterable
            style="width: 195px"
            clearable
            placeholder="请选择"
            @change="changeDepartmentId"
        >
            <el-option
                v-for="(item, index) in departmentList"
                v-if="index > 0"
                :key="item.id"
                :label="item.departmentName"
                :value="item.id"
            >
            </el-option>
        </el-select> -->
    </span>
</template>

<script>
import { roleListAll } from '@/api/customer/customer.js';
export default {
    name: '',
    props: [
        'departmentWidth',
        'adminWidth',
        'depSearch',
        'searchUser',
        'isSaleCost',
        'searchType',
    ],
    data() {
        return {
            isJurisdiction: true,
            departmentList: [],
            departmentId: undefined,
            adminId: '',
            btnP: {},
            userName: [],
            myAdminId: sessionStorage.getItem('adminId'),
        };
    },
    components: {},
    created() {},
    methods: {
        getData(btnP, data) {
            this.btnP = btnP;
            this.departmentList = this.$Tree.treeDataTranslate(data);
        },
        // 获取部门成员
        getUserList(departmentId) {
            let data = {
                param: {
                    departmentId,
                },
            };
            roleListAll(data).then((res) => {
                this.userName = res.data;
            });
        },
        cascaderClick(nodeData) {
            this.adminId = '';
            this.departmentId = nodeData.id;
            this.getUserList(nodeData.id);
            this.$emit('searchData', {
                departmentId: nodeData.id,
                adminId: this.adminId,
            });
            this.$refs.cascader.checkedValue = nodeData.departmentName;
            this.$refs.cascader.computePresentText();
            this.$refs.cascader.toggleDropDownVisible(false);
        },
        changea(val) {
            this.adminId = '';
            this.userName = [];
            this.$emit('searchData', {
                departmentId: val || '',
                adminId: this.adminId,
            });
        },
        changeAdmin() {
            this.$emit('searchData', {
                departmentId: this.departmentId,
                adminId: this.adminId,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .elm-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.title {
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin: 0 16px;
}
</style>
